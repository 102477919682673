<template>
    <div>
        <landing-header></landing-header>
        <main class="static-page">
            <div class="m-3 p-3 text-center">
                <h4>TERMS AND CONDITIONS FOR INTERNATIONAL SHIPMENTS</h4>
            </div>
            <section class="p-5">
                <p>1.) International shipments take 5 -7 working days for delivery.</p>
                <p>2.) Content of parcel being shipped must be clearly declared by the client.</p>
                <p>3.) Value of all parcels must be declared by the client.</p>
                <p>4.) Parcels valued less than $50 (N20,000) by client are covered under FTD
insurance, and clients can be reimbursed in the event of damage to such parcels.</p>
                <p>5.) For parcels valued over $50 (N20,000) by client, extra charge of 10% of the
declared value will be added to the shipping cost.
</p>
                <p>6.) Customs duty of 30 - 40% of value declared on all parcels is to be paid by the
client upon receipt.
</p>
                <p>7.) Returning shipment back to the client will incur an extra charge, same as cost of
shipping the parcel.
</p>
            <p>
                <strong>NOTE:</strong> Upon issuance of receipt provided by client (when mandated), customs in the
recipients’ country can still choose to revalue clients parcels, FTD Logistics is not liable
for any extra charge incurred at this point.

            </p>
            </section>
        </main>
        <landing-footer></landing-footer>
    </div>
</template>
<script>
import footerVue from '../layout/footer.vue'
import headerVue from '../layout/header.vue'
export default {
    name: "InternationalShipmentTerms",
    components: {landingHeader: headerVue, landingFooter: footerVue}
}
</script>